import { Tabs } from "antd";
import React from "react";
import DetailFormUpdate from "./UpdateProperties/DetailForm";
import AminitiesForm from "./UpdateProperties/AminitiesForm";
import ImagesForm from "./UpdateProperties/ImagesForm";

export default function UpdateProperty() {

  const items = [
    {
      key: "1",
      label: "Details",
      children: <DetailFormUpdate />,
    },
    {
      key: "2",
      label: "Aminities",
      children: <AminitiesForm />,
    },
    {
      key: "3",
      label: "Images",
      children: <ImagesForm />,
    },
  ];
  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
