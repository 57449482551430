import React from "react";
import { Avatar, Button, Empty, Input, Upload } from "antd";
import { IoMdCloseCircle } from "react-icons/io";

export default function ImagesForm({ fileList, setFileList }) {

  const resizeImage = (file, maxSize, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const scaleSize = maxSize / Math.max(img.width, img.height);
        canvas.width = img.width * scaleSize;
        canvas.height = img.height * scaleSize;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          callback(blob);
        }, file.type, 0.7); // Adjust quality as needed
      };
    };
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const resizedFiles = [];
    selectedFiles.forEach((file) => {
      resizeImage(file, 600, (resizedBlob) => {
        resizedFiles.push(new File([resizedBlob], file.name, { type: file.type }));
        if (resizedFiles.length === selectedFiles.length) {
          setFileList(resizedFiles);
        }
      });
    });
  };

  return (
    <>
      {" "}
      <Input
        type="file"
        multiple
        accept=".jpg, .jpeg, .png"
        onChange={(e) => handleFileChange(e)}
      />
      <div className="row mb-4">
        {fileList.length > 0 ? (
          fileList.map((value, valIndex) => (
            <div className="col-4 mt-4 d-flex align-items-center justify-content-center mb-4 position-relative">
              <Avatar
                size={140}
                shape="square"
                src={URL.createObjectURL(value)}
              />
              <span
                onClick={() =>
                  setFileList((prev) =>
                    prev.filter((val, index) => index !== valIndex)
                  )
                }
                style={{
                  position: "absolute",
                  background: "red",
                  border: "unset",
                  color: "red",
                  top: 5,
                  right: 15,
                  height:20,
                  width:20,
                  fontSize:15,
                  display:"flex",
                  alignItems:"center",
                  justifyContent:"center",
                  borderRadius:50,
                  color:"#fff",
                  cursor:"pointer"
                }}
              >
                <IoMdCloseCircle />
              </span>
            </div>
          ))
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
}
