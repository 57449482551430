import { Button, Form, Input, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";
import { get, post } from "../../../../services/userAuthAPI";
import { useDrawer } from "../../../../context/drawer";

const residentialSpace = [
  "Independent Houses/Bungalows",
  "Apartments/Flats",
  "Villas",
  "Duplexes",
  "Row Houses/Townhouses",
  "Condominiums (Condos)",
  "Builder Floors",
  "Farmhouses",
  "Gated Communities",
  "Cooperative Housing Societies",
  "Studio Apartments/Serviced Apartments",
  "Slum Housing",
];

const comercialSpace = [
  "Office Buildings",
  "Retail Spaces",
  "Industrial Properties",
  "Hotels and Hospitality",
  "Mixed-use Developments",
  "Co-working Spaces",
  "Healthcare Facilities",
  "Educational Institutions",
  "Logistics Parks",
];

export default function DetailFormUpdate() {
  const [selectedCategory, setSelectedCategory] = useState("residential");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const { drawerData, toggleDrawer } = useDrawer();

  useEffect(() => {
    form.setFieldsValue({
      ...drawerData.value,
      property_price: formatNumber(drawerData.value.property_price).split(
        " "
      )[0],
      price_type: formatNumber(drawerData.value.property_price).split(" ")[1],
    });
  }, [drawerData]);

  const handleUpdate = async (value) => {
    try {
      setLoading(true);
      const formsData = new FormData();

      for (const [key, val] of Object.entries(value)) {
        if (key === "property_price") {
          formsData.append(
            "property_price",
            convertToInteger(value.property_price + " " + value.price_type)
          );
        } else {
          formsData.append(key, val);
        }
      }

      formsData.append("action", "updateProperty");

      const resp = await post("/property.php", formsData);

      if (resp) {
        setLoading(false);
        message.success("Property Uploded successfully");
        toggleDrawer();
      }
    } catch (error) {
      console.log(error);
      message.error("Unable to update please try again");
    }
  };

  function convertToInteger(amountStr) {
    // Trim and convert the input string to lowercase
    const normalizedStr = amountStr.trim().toLowerCase();

    // Define conversion rates
    const lakhMultiplier = 100000;
    const thousandMultiplier = 1000;
    const croreMultiplier = 10000000;

    // Match and convert based on suffix
    if (normalizedStr.endsWith("cr")) {
      return (
        parseFloat(normalizedStr.replace("cr", "").trim()) * croreMultiplier
      );
    } else if (normalizedStr.endsWith("lac")) {
      return (
        parseFloat(normalizedStr.replace("lac", "").trim()) * lakhMultiplier
      );
    } else if (normalizedStr.endsWith("k")) {
      return (
        parseFloat(normalizedStr.replace("k", "").trim()) * thousandMultiplier
      );
    } else {
      // Default case: assume it’s a plain number
      return parseFloat(normalizedStr);
    }
  }

  function formatNumber(num) {
    if (num >= 1000 && num < 10000) {
      return (num / 1000).toFixed(1) + " k";
    } else if (num >= 100000 && num < 10000000) {
      return (num / 100000).toFixed(1) + " lac";
    } else if (num >= 10000000 && num <= 1000000000) {
      return (num / 10000000).toFixed(1) + " cr";
    } else {
      return num.toString(); // For numbers outside the specified ranges
    }
  }

  return (
    <div>
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        onFinish={handleUpdate}
      >
        <div className="row">
        <div className="col-md-12 d-none">
            <Form.Item label="Name" name="property_id">
              <Input placeholder="Property Name" className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item label="Name" name="property_name">
              <Input placeholder="Property Name" className="formInput" />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Type" name="property_for">
              <Select
                placeholder="Property For"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                options={[
                  {
                    label: "Outright",
                    value: "outright",
                  },
                  {
                    label: "Lease",
                    value: "lease",
                  },
                  {
                    label: "New Construction",
                    value: "new construction",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Property Type" name="property_type">
              <Select
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                placeholder="Property Category"
                onChange={(val) => setSelectedCategory(val)}
                options={[
                  {
                    label: "Residential",
                    value: "residential",
                  },
                  {
                    label: "Commercial",
                    value: "commercial",
                  },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Property Category" name="property_categ">
              <Select
                placeholder="Select Category"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
              >
                {(selectedCategory === "residential"
                  ? residentialSpace
                  : comercialSpace
                )?.map((valueData) => (
                  <Select.Option value={valueData} label={valueData}>
                    {valueData}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Configuration" name="property_configuration">
              <Input style={{ width: "100%" }} placeholder="Configuration" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Property Size" name="property_size">
              <Input placeholder="Property Size" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Age of Property" name="property_age">
              <Input placeholder="Age of property" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Price Type" name="price_type">
              <Select
                placeholder="Price Type"
                options={[
                  { label: "K", value: "K" },
                  { label: "Lac", value: "Lac" },
                  { label: "Cr", value: "Cr" },
                  { label: "Price on request", value: "Price on request" },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Price" name="property_price">
              <Input
                style={{ width: "100%" }}
                className="formInput"
                placeholder="0"
                prefix={<PiCurrencyInrBold />}
                min={0}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Bathroom" name="property_bathroom">
              <Input className="formInput" placeholder="Bathroom" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Parking" name="property_parking">
              <Select
                placeholder="Parking"
                className="formInput"
                options={Array.from({ length: 10 }, (_, index) => ({
                  label: index + 1,
                  value: index + 1,
                }))}
              />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item label="Address" name="property_address">
              <Input placeholder="Address" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Location" name="property_locality">
              <Input className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="City" name="property_city">
              <Input placeholder="City" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="State" name="property_state">
              <Input placeholder="State" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Country" name="property_country">
              <Input placeholder="Country" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Pincode" name="property_pincode">
              <Input placeholder="Pincode" className="formInput" />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Property Active" name="property_active">
              <Select
                className="formInput"
                placeholder="Property Active"
                options={[
                  { label: "Active", value: "active" },
                  { label: "Unactive", value: "unactive" },
                ]}
              />
            </Form.Item>
          </div>

          <Form.Item label="Description" name="property_desc">
            <TextArea showCount maxLength={500} />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="otherButtons" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
