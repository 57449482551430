import { Avatar, Button, Empty, Input, message, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { useDrawer } from "../../../../context/drawer";
import { post } from "../../../../services/userAuthAPI";
import { IoMdCloseCircle } from "react-icons/io";

export default function ImagesForm() {
  const { drawerData, toggleDrawer } = useDrawer();

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const imageData = drawerData?.value?.images?.map((valueData) => ({
      image_id: valueData.image_id,
      thumbUrl: valueData.image_link,
      name: valueData.image_link,
    }));

    setFileList(imageData);
  }, [drawerData]);

  const removeImage = async (id, valIndex) => {
    if (window.confirm("Are you sure you want to remove image ? ")) {
      try {
        setLoading(true);
        const formData = new FormData();

        formData.append("image_id", id);
        formData.append("action", "removeImage");

        const resp = await post("/property.php", formData);

        if (resp) {
          message.success("Uploaded success");
          setFileList((prev) =>
            prev.filter((val, index) => index !== valIndex)
          );
          toggleDrawer();
        }
      } catch (error) {
        message.error("Unable to remove please try again !");
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadImage = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "addImages");
      formData.append("property_id", drawerData?.value?.property_id);
      fileList.forEach((val) => {
        if (val.type === "file") {
          formData.append("images[]", val.file);
        }
      });

      const resp = await post("/property.php", formData);

      if (resp) {
        message.success("Image uploaded successfully");
        toggleDrawer();
      } else {
        throw new Error("Image upload failed. Please try again.");
      }
    } catch (error) {
      message.error(error.message || "An error occurred during image upload.");
    } finally {
      setLoading(false);
    }
  };

  const resizeImage = (file, maxSize, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const scaleSize = maxSize / Math.max(img.width, img.height);
        canvas.width = img.width * scaleSize;
        canvas.height = img.height * scaleSize;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            callback(blob);
          },
          file.type,
          0.7
        ); // Adjust quality as needed
      };
    };
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const resizedFilesPromises = selectedFiles.map(
      (file) =>
        new Promise((resolve) => {
          resizeImage(file, 600, (resizedBlob) => {
            resolve(new File([resizedBlob], file.name, { type: file.type }));
          });
        })
    );

    Promise.all(resizedFilesPromises).then((resizedFiles) => {
      const updatedFiles = resizedFiles.map((file) => ({
        thumbUrl: URL.createObjectURL(file),
        file: file,
        type: "file",
      }));
      setFileList((prev) => [...prev, ...updatedFiles]);
    });
  };

  console.log(fileList);
  return (
    <Spin spinning={loading}>
      <Input
        type="file"
        multiple
        accept=".jpg, .jpeg, .png"
        onChange={(e) => handleFileChange(e)}
      />
      <div className="row mb-4">
        {fileList.length > 0 ? (
          fileList.map((value, valIndex) => (
            <div className="col-4 mt-4 d-flex align-items-center justify-content-center mb-4 position-relative">
              <Avatar size={140} shape="square" src={value.thumbUrl} />
              <span
                onClick={() => removeImage(value.image_id, valIndex)}
                style={{
                  position: "absolute",
                  background: "red",
                  border: "unset",
                  color: "red",
                  top: 5,
                  right: 15,
                  height: 20,
                  width: 20,
                  fontSize: 15,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 50,
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                <IoMdCloseCircle />
              </span>
            </div>
          ))
        ) : (
          <Empty />
        )}
      </div>
      <Button
        onClick={handleUploadImage}
        disabled={fileList.length == 0}
        className="bg-success text-white w-100"
      >
        Upload Images
      </Button>
    </Spin>
  );
}
