import { Form, Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { PiCurrencyInrBold } from "react-icons/pi";

const residentialSpace = [
  "Apartment /Flat",
  "Bungalow",
  "Duplex",
  "Triplex",
  "Penthouse",
  "Row House",
  "Condominium (Condo)",
  "Builder Floor",
  "Farm House",
  "Gated Community",
  "Studio Apartment ",
];

const comercialSpace = [
  "Shop",
  "Office",
  "Showroom",
  "Commercial Space",
  "Commercial Building",
  "Restaurant Space",
  "Residence + Commercial",
  "Garrage",
  "Warehouse/ Godown",
];

export default function DetailForm({
  setFormData,
  formData,
  setSearchData,
  locations,
}) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [locationType, setLocationType] = useState("Old");

  return (
    <div>
      <Form layout="vertical" autoComplete="off">
        <div className="row">
          <div className="col-md-12">
            <Form.Item label="Name" name="property_name">
              <Input
                placeholder="Property Name"
                className="formInput"
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_name: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Type" name="property_for">
              <Select
                placeholder="Property For"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                value={formData.property_for}
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_for: value,
                  }))
                }
                options={[
                  {
                    label: "Outright",
                    value: "outright",
                  },
                  {
                    label: "Lease",
                    value: "lease",
                  },
                  {
                    label: "New Construction",
                    value: "new construction",
                  },
                ]}
              />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item label="Property Type" name="property_type">
              <Select
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                placeholder="Property Category"
                value={selectedCategory}
                onChange={(value) => {
                  setSelectedCategory(value);
                  setFormData((prev) => ({
                    ...prev,
                    property_type: value,
                  }));
                }}
                options={[
                  {
                    label: "Residential",
                    value: "residential",
                  },
                  {
                    label: "Commercial",
                    value: "commercial",
                  },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Property Category" name="property_categ">
              <Select
                placeholder="Select Category"
                style={{ minHeight: "35px", borderColor: "#afafaf" }}
                value={formData.property_categ}
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_categ: value,
                  }))
                }
              >
                {(selectedCategory === "residential"
                  ? residentialSpace
                  : comercialSpace
                )?.map((valueData) => (
                  <Select.Option value={valueData} label={valueData}>
                    {valueData}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Configuration" name="property_configuration">
              <Input
                style={{ width: "100%" }}
                placeholder="Configuration"
                value={formData.property_configuration}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_configuration: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Property Size" name="property_size">
              <Input
                placeholder="Property Size"
                className="formInput"
                value={formData.property_size}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_size: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Age of Property" name="property_age">
              <Input
                placeholder="Age of property"
                className="formInput"
                value={formData.property_age}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_age: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Price Type" name="price_type">
              <Select
                placeholder="Price Type"
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    price_type: value,
                  }))
                }
                options={[
                  { label: "K", value: "k" },
                  { label: "Lac", value: "lac" },
                  { label: "Cr", value: "cr" },
                  { label: "Price on request", value: "Price on request" },
                ]}
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Price" name="property_price">
              <Input
                style={{ width: "100%" }}
                className="formInput"
                placeholder="0"
                prefix={<PiCurrencyInrBold />}
                min={0}
                value={formData.property_price}
                disabled={formData.price_type === "Price on request"}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_price: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Bathroom" name="property_bathroom">
              <Input
                className="formInput"
                placeholder="Bathroom"
                value={formData.property_bathroom}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_bathroom: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Parking" name="property_parking">
              <Select
                placeholder="Parking"
                className="formInput"
                value={formData.property_parking}
                onChange={(val) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_parking: val,
                  }))
                }
                options={Array.from({ length: 10 }, (_, index) => ({
                  label: index + 1,
                  value: index + 1,
                }))}
              />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item label="Address" name="property_address">
              <Input
                placeholder="Address"
                className="formInput"
                value={formData.property_address}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_address: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Location" name="property_locality">
              <Input
                className="formInput"
                value={formData.property_locality}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_locality: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="City" name="property_city">
              <Input
                placeholder="City"
                className="formInput"
                value={formData.property_city}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_city: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="State" name="property_state">
              <Input
                placeholder="State"
                className="formInput"
                value={formData.property_state}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_state: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Country" name="property_country">
              <Input
                placeholder="Country"
                className="formInput"
                value={formData.property_country}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_country: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Pincode" name="property_pincode">
              <Input
                placeholder="Pincode"
                className="formInput"
                value={formData.property_pincode}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_pincode: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </div>

          <div className="col-md-6">
            <Form.Item label="Property Active" name="property_active">
              <Select
                className="formInput"
                placeholder="Property Active"
                value={formData.property_active}
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    property_active: value,
                  }))
                }
                options={[
                  { label: "Active", value: "active" },
                  { label: "Unactive", value: "unactive" },
                ]}
              />
            </Form.Item>
          </div>

          <Form.Item label="Description" name="property_desc">
            <TextArea
              showCount
              maxLength={500}
              value={formData.property_desc}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  property_desc: e.target.value,
                }))
              }
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
