import React, { useEffect, useState } from "react";
import TitleComp from "../components/Title/title";
import { useDrawer } from "../context/drawer";
import DrawerComp from "../components/Drower/Drawer";
import TableComp from "../components/table/tables";
import { get, post } from "../services/userAuthAPI";
import { Badge, Button, message, Tag } from "antd";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";

export default function Properties() {
  const columns = [
    {
      title: "ID",
      dataIndex: "property_auto_id",
      key: "property_auto_id",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "property_name",
      key: "property_name",
      width: "10%",
    },
    {
      title: "For",
      dataIndex: "property_for",
      key: "property_for",
      width: "10%",
    },
    {
      title: "Category",
      dataIndex: "property_categ",
      key: "property_categ",
      width: "10%",
    },
    {
      title: "Type",
      dataIndex: "property_type",
      key: "property_type",
      width: "10%",
    },
    {
      title: "Location",
      dataIndex: "property_locality",
      key: "property_locality",
      width: "10%",
    },
    {
      title: "Size",
      dataIndex: "property_size",
      key: "property_size",
    },
    {
      title: "Price",
      dataIndex: "property_price",
      key: "property_price",
    },
    {
      title: "Configuration",
      dataIndex: "property_configuration",
      key: "property_configuration",
    },
    {
      title: "Is Active",
      dataIndex: "property_active",
      key: "property_active",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "",
    },
  ];

  const [fetchList, setFetchList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageComp, setPageComp] = useState(1);
  const [limitComp, setLimitComp] = useState(10);
  const [totalData, setTotalData] = useState(null);
  const { setDrawerData, drawerData } = useDrawer();

  function formatNumberToIndianUnits(number) {
    const price = parseInt(number);
    if (price >= 10000000) {
      // Crore
      return (price / 10000000).toFixed(2) + " Cr";
    } else if (price >= 100000) {
      // Lakh
      return (price / 100000).toFixed(2) + " Lakh";
    } else if (price >= 1000) {
      // Thousand
      return (price / 1000).toFixed(2) + " K";
    }
    return price.toString();
  }
  const handleDrawerAdd = () => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "propertyAdd",
      title: "Property Add",
      ID: null,
    });
  };

  const fetchData = async (page, limit, search, category, type) => {
    try {
      setLoading(true);

      const resp = await get(
        `/property.php?action=fetch&limit=${limit}&page=${page}${
          search !== "" ? "&property_name=" + search : ""
        }${category !== "all" ? "&property_categ=" + category : ""}${
          type !== "all" ? "&property_type=" + type : ""
        }`
      );

      if (resp) {
        setLoading(false);
        setTotalData(resp.total);

        const data = resp.list.map((value) => ({
          ...value,
          property_price:
            value?.price_type === "Price on request"
              ? value?.price_type
              : value?.property_price !== "" &&
                formatNumberToIndianUnits(value?.property_price),
          property_active: (
            <Tag
              color={value.property_active === "active" ? "#87d068" : "#f50"}
            >
              {value.property_active}
            </Tag>
          ),
          action: (
            <div className="buttonGroup">
              <Button
                onClick={() => handleDrawerUpdate(value)}
                className="otherButtons edit"
              >
                <FaEdit />
              </Button>
              <Button
                className="otherButtons deactive"
                onClick={() => removeProperty(value.property_id)}
              >
                <FaTrashAlt />
              </Button>
            </div>
          ),
        }));

        setFetchList(data);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(pageComp, limitComp, "", "all", "all");
  }, [limitComp, pageComp]);

  const handleDrawerUpdate = (value) => {
    setDrawerData({
      ...drawerData,
      isOpen: true,
      type: "propertyUpdate",
      title: "Property Update",
      ID: null,
      value: value,
    });
  };

  const removeProperty = async (id) => {
    try {
      if (window.confirm("Are you sure you want to remove product")) {
        const formData = new FormData();

        formData.append("is_active", 0);
        formData.append("property_id", id);
        formData.append("action", "updateProperty");

        const resp = await post("/property.php", formData);

        if (resp) {
          message.success("Remove successfully");
        }
      }
    } catch (error) {
      message.error("Unble to remove please try again !");
      console.error(error);
    }
  };

  return (
    <div>
      <TitleComp
        name="Properties"
        type="Properties"
        totalData={totalData}
        isShowButton={true}
        handleDrawerAdd={handleDrawerAdd}
      />
      <TableComp
        data={fetchList}
        columns={columns}
        limitComp={limitComp}
        pageComp={pageComp}
        setPageComp={setPageComp}
        totalData={totalData}
        setLimitComp={setLimitComp}
        loading={loading}
        isShowFilter={true}
        fetchData={fetchData}
      />
      <DrawerComp />
    </div>
  );
}
