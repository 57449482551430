import React, { useEffect, useRef, useState } from "react";
import DetailForm from "./AddProperties/DetailForm";
import { MdOutlineNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { Button, message } from "antd";
import AminitiesForm from "./AddProperties/AminitiesForm";
import { get, post } from "../../../services/userAuthAPI";
import ImagesForm from "./AddProperties/ImagesForm";
import { useDrawer } from "../../../context/drawer";

export default function AddProperties() {
  const [activeForm, setActiveForm] = useState("detail");
  const [aminties, setAminties] = useState([]);
  const [locations, setLocations] = useState([]);

  const [fileList, setFileList] = useState([]);

  const [additionFeature, setAdditionFeature] = useState([]);
  const [furniture, setFurniture] = useState([]);
  const [searchData, setSearchData] = useState({ search: "", type: "" });

  const [selectedAminties, setSelectedAminties] = useState([]);
  const [selectedAdditionFeature, setSelectedAdditionFeature] = useState([]);
  const [selctedFurniture, setSelctedFurniture] = useState([]);

  const [loading, setLoading] = useState(false);

  const { toggleDrawer } = useDrawer();

  const [formData, setFormData] = useState({
    property_name: "",
    property_slug: "",
    property_for: "",
    property_type: "",
    property_categ: "",
    property_configuration: "",
    property_size: "",
    property_age: "",
    price_type: "",
    property_price: "",
    property_bathroom: "",
    property_parking: "",
    property_address: "",
    property_locality: "",
    property_city: "",
    property_price: "",
    property_state: "",
    property_country: "",
    property_pincode: "",
    property_active:"",
    property_desc:""
  });


  const fetchLocation = async (type, search) => {
    try {
      const resp = await get(
        `/property.php?action=fetchLocation${
          search !== "" ? "&locality=" + search : ""
        }`
      );

      if (resp) {
        setLocations(resp.list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAminites = async (type, search) => {
    try {
      if (searchData?.type !== "") {
        const resp = await get(
          `/property.php?action=fetchListAminites&limit=5&page=1&aminitie_type=${type}${
            search !== "" ? "&aminitie_name=" + search : ""
          }`
        );

        if (type === "furniture") {
          setFurniture(resp.list);
        }

        if (type === "amenities") {
          setAminties(resp.list);
        }

        if (type === "additional_feature") {
          setAdditionFeature(resp.list);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const dbounceTime = setTimeout(() => {
      switch (activeForm) {
        case "detail":
          fetchLocation(searchData.type, searchData.search);
          break;
        case "aminites":
          fetchAminites(searchData.type, searchData.search);
          break;
        default:
          break;
      }
    }, 300);

    return () => clearTimeout(dbounceTime);
    // eslint-disable-next-line
  }, [searchData]);

  const handleSubmitClick = async () => {
    try {

      setLoading(true);
      const formsData = new FormData();

      for (const [key, val] of Object.entries(formData)) {
        if (key === "property_price") {
          formsData.append(
            "property_price",
            convertToInteger(formData.property_price + " " + formData.price_type)
          );
        } else {
          formsData.append(key, val);
        }
      }

      if (fileList.length > 0) {
        fileList.forEach((element) => {
          formsData.append("images[]", element);
        });
      }

      if (selectedAminties.length > 0) {
        selectedAminties.forEach((element) => {
          formsData.append("amenities[]", element.split("-")[1]);
        });
      }

      if (selctedFurniture.length > 0) {
        selctedFurniture.forEach((element) => {
          formsData.append("amenities[]", element.split("-")[1]);
        });
      }

      if (selectedAdditionFeature.length > 0) {
        selectedAdditionFeature.forEach((element) => {
          formsData.append("amenities[]", element.split("-")[1]);
        });
      }
      formsData.append("action", "add");

      const resp = await post("/property.php", formsData);

      if (resp) {
        toggleDrawer();
        message.success("Property Uploded successfully");
      }
    } catch (error) {
      message.error("Unable add property try again");
      console.error(error);
    }finally{
      setLoading(false);
    }
  };

  function convertToInteger(amountStr) {
    // Trim and convert the input string to lowercase
    const normalizedStr = amountStr.trim().toLowerCase();

    // Define conversion rates
    const lakhMultiplier = 100000;
    const thousandMultiplier = 1000;
    const croreMultiplier = 10000000;

    // Match and convert based on suffix
    if (normalizedStr.endsWith("cr")) {
      return (
        parseFloat(normalizedStr.replace("cr", "").trim()) * croreMultiplier
      );
    } else if (normalizedStr.endsWith("lac")) {
      return (
        parseFloat(normalizedStr.replace("lac", "").trim()) * lakhMultiplier
      );
    } else if (normalizedStr.endsWith("k")) {
      return (
        parseFloat(normalizedStr.replace("k", "").trim()) * thousandMultiplier
      );
    } else {
      // Default case: assume it’s a plain number
      return parseFloat(normalizedStr);
    }
  }


  return (
    <>
      {activeForm === "detail" && (
        <DetailForm
          locations={locations}
          setSearchData={setSearchData}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {activeForm === "aminites" && (
        <AminitiesForm
          aminties={aminties}
          setAminties={setAminties}
          additionFeature={additionFeature}
          setAdditionFeature={setAdditionFeature}
          furniture={furniture}
          setFurniture={setFurniture}
          selectedAminties={selectedAminties}
          setSelectedAminties={setSelectedAminties}
          selectedAdditionFeature={selectedAdditionFeature}
          setSelectedAdditionFeature={setSelectedAdditionFeature}
          selctedFurniture={selctedFurniture}
          setSelctedFurniture={setSelctedFurniture}
          setSearchData={setSearchData}
        />
      )}
      {activeForm === "images" && (
        <ImagesForm fileList={fileList} setFileList={setFileList} />
      )}

      <div className="buttonGroup w-100 d-flex justify-content-end">
        {activeForm !== "detail" && (
          <Button
            className="otherButtons deactive mx-2"
            icon={<GrFormPrevious />}
            onClick={() =>
              setActiveForm((prev) =>
                prev === "images" ? "aminites" : "detail"
              )
            }
          >
            Previous
          </Button>
        )}
        {activeForm === "images" ? (
          <Button
            className="otherButtons active"
            icon={<MdOutlineNavigateNext />}
            onClick={handleSubmitClick}
            loading={loading}
          >
            Submit
          </Button>
        ) : (
          <Button
            className="otherButtons active"
            icon={<MdOutlineNavigateNext />}
            onClick={() =>
              setActiveForm((prev) =>
                prev === "detail" ? "aminites" : "images"
              )
            }
          >
            Next
          </Button>
        )}
      </div>
    </>
  );
}
